<template>
<b-card header="Questions">
  <b-form inline>
    <b-input-group class="mb-2 mr-2">
      <b-form-input
        v-model="filter"
        type="search"
        id="filterInput"
        placeholder="Type to Search"
        @input="handleFilterInput"
      ></b-form-input>
    </b-input-group>
    <b-button
      :to="moduleId ? { name: 'AdminQuestionAddInModule', params: { moduleId: moduleId } } : { name: 'AdminQuestionAdd' }"
      variant="primary"
      class="mb-2 mr-2"
    >Create
    </b-button>
  </b-form>
  
  <div class="border p-2 d-flex">
    <div class="eu-w-20 font-weight-bold">Text</div>
    <div class="eu-w-20 font-weight-bold">Type</div>
    <div class="eu-w-10 font-weight-bold">Active</div>
    <div class="eu-w-10 font-weight-bold">Dashboard</div>
    <div class="eu-w-10 font-weight-bold">Order</div>
    <div class="eu-w-20 font-weight-bold">Envronment</div>
    <div class="eu-w-10 font-weight-bold">Actions</div>
  </div>

  <draggable v-model="questions" group="question" @start="drag=true" @end="drag=false; handleDrop($event)" v-if="!loading && moduleId">
    <question-card-row v-for="(question) in questions" :key="question.id" :question="question" @update="fetchData"/>
  </draggable>
  <div v-else-if="!loading && !moduleId">
    <question-card-row v-for="(question) in questions" :key="question.id" :question="question" @update="fetchData"/>
  </div>
  <div class="text-center p-5 border" v-else>
    <b-spinner label="Loading" variant="secondary"></b-spinner>
  </div>
</b-card>
</template>

<script>
import draggable from 'vuedraggable'
import QuestionCardRow from './QuestionCardRow.vue';
import API from "@/api";
export default {
  name: "QuestionsCard",
  props: ["moduleId"],
  components: {
    draggable,
    QuestionCardRow
  },
  data() {
    return {
      loading: true,
      filter: "",
      questions: [],
      allQuestions: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    handleDrop(event) {
      const min = Math.min(event.oldIndex, event.newIndex);
      const max = Math.max(event.oldIndex, event.newIndex);

      const indexArray = Array.apply(null, {length: max + 1}).map(Number.call, Number).slice(min);
      indexArray.forEach(idx => {
        let filter = {
          _id: this.questions[idx]._id,
        };

        let updateData = { ...this.questions[idx] };
        updateData.order = idx;
        delete updateData._id;
        updateData.lastUpdated = new Date().getTime();
        let update = { $set: updateData };
        API.update("questions", filter, update).catch(
          (error) => {
            console.log(error);
          }
        );
      })
    },
    handleFilterInput() {
      this.questions = this.allQuestions.filter(question => question.text.toLowerCase().includes(this.filter.toLowerCase()));
    },
    async fetchData() {
      const realmApp = API.realmApp;
      const mongo = realmApp.services.mongodb("mongodb-atlas");
      const mongoCollection = mongo.db("eunoe").collection("questions");
      const query = this.moduleId ? {moduleId: this.moduleId} : {};
      const questionResults = await mongoCollection.find(query);
      questionResults.sort((a, b) => a.order - b.order);
      this.questions = questionResults;
      this.allQuestions = this.questions;
      this.loading = false;
    },
  },
};
</script>