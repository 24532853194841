<template>
  <div class="border-bottom border-left border-right p-2 d-flex align-items-center">
    <div class="eu-w-20">{{question.text}}</div>
    <div class="eu-w-20">{{question.type}}</div>
    <div class="eu-w-10">{{question.active}}</div>
    <div class="eu-w-10">{{question.dashboard}}</div>
    <div class="eu-w-10">{{question.order}}</div>
    <div class="eu-w-15">{{question.environment}}</div>
    <div class="eu-w-15 text-right">
      <b-button @click="deleteQuestionConfirm(question)" class="mr-1">
        <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
      </b-button>
      <b-button
        :to="{ name: 'AdminQuestionEdit', params: { id: question._id } }"
      >
        <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
      </b-button>
    </div>
  </div>
</template>

<script>
import API from "@/api";

export default {
  props: ["question"],
  data() {
    return {
    };
  },
  methods: {
    async deleteQuestion(question) {
      this.loading = true;

      let filter = {
        _id: question._id,
      };
      await API.delete("questions", filter);
      this.questions = [];
      this.$emit('update');
    },
    deleteQuestionConfirm(question) {
      let message = "Are you sure you want to delete question " + question._id + "?";

      this.$bvModal.msgBoxConfirm(message).then(async (value) => {
        if (value) {
          this.deleteQuestion(question);
        } else {
          // Delete cancelled.
        }
      });
    },
  }
};
</script>